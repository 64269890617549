import authConstants from '../constants/auth.constants';
import authService from '../services/auth.service';
import maintenanceActions from './maintenance.actions';

const authActions = {
  getUserProfile,
  checkTokenStatus,
  deleteToken,
};

function getUserProfile() {
  return (dispatch) => {
    dispatch({ type: authConstants.GET_USER_PROFILE_REQUEST });
    authService
      .getUserProfile()
      .then((response) => {
        if (response) {
          dispatch({ type: authConstants.GET_USER_PROFILE_SUCCESS, response });
        }
      })
      .catch((error) => {
        dispatch({ type: authConstants.GET_USER_PROFILE_ERROR, error });
      });
  };
}

function checkTokenStatus(permissions = []) {
  return (dispatch) => {
    dispatch({ type: authConstants.CHECK_TOKEN_STATUS_REQUEST });
    authService
      .validatePermissions(permissions)
      .then(() => {
        dispatch({ type: authConstants.CHECK_TOKEN_STATUS_SUCCESS });
      })
      .catch((error) => {
        dispatch(maintenanceActions.getMaintenanceStatus());
        dispatch({ type: authConstants.CHECK_TOKEN_STATUS_FAILURE, error });
      });
  };
}

const deleteTokenDefaultCallback = () => {
  window.location.href = process.env.REACT_APP_AUTH_FRONT_URL;
};
function deleteToken(callback = deleteTokenDefaultCallback) {
  return (dispatch) => {
    dispatch({ type: authConstants.DELETE_TOKEN_USER_REQUEST });
    authService
      .deleteToken()
      .then(() => {
        dispatch({ type: authConstants.DELETE_TOKEN_USER_SUCCESS });
        callback();
      })
      .catch((error) => {
        dispatch({ type: authConstants.DELETE_TOKEN_USER_FAILURE, error });
      });
  };
}

export default authActions;
