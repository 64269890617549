import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

window.UE = window.UE || {};

const UserComUrlChange = () => {
  const location = useLocation();

  useEffect(() => {
    if (typeof window.UE?.pageHit === 'function') {
      window.UE.pageHit();
    }
  }, [location, location.pathname]);

  return null;
};

export default UserComUrlChange;
