import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import FullPageModal from '../../components/FullPageModal/FullPageModal';
import PhoneVerificationForm from '../../components/pages/RegisterActivation/PhoneVerificationForm';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import Header from '../../components/Header/Header';
import PasswordForm from '../../components/PasswordForm/PasswordForm';
import Loader from '../../components/Loader/Loader';
import registerActions from '../../redux/actions/register.actions';
import authActions from '../../redux/actions/auth.actions';
import PhoneConfirmForm from '../../components/pages/RegisterActivation/PhoneConfirmForm';
import redirectHelper from '../../helpers/redirectHelper';
import gaHelper from '../../helpers/gaHelper';
import { REQUEST_STATUS } from '../../models/common.model';
import { getUrlParam } from '../../helpers/history';

const RegisterActivationPage = () => {
  const step = useSelector((state) => state.register.activationStep);
  const registerCodeStatus = useSelector((state) => state.register.registerCodeStatus);
  const getRegisterCodeStatus = useSelector((state) => state.register.getRegisterCodeStatus);
  const tokenStatus = useSelector((state) => state.auth.tokenStatus);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const routerParams = useParams();
  const activationCode = routerParams.code.split('&')[0];

  const routerHistory = useHistory();
  const { search } = useLocation();
  const isJoinDocument = Boolean(getUrlParam('joinDocument', search));

  useEffect(() => {
    document.title = t('titles.register');
    switch (registerCodeStatus) {
      case 'PHONE_VALIDATION_STAGE':
        break;
      default:
        break;
    }
  }, [registerCodeStatus, t]);

  useEffect(() => {
    switch (step) {
      case 0:
        dispatch(registerActions.getRegisterCodeStatus(activationCode));
        break;
      case 4:
        dispatch(authActions.checkTokenStatus(['AUTH_USER_GET_PROFILE']));
        gaHelper.initialize();
        gaHelper.sendEvent('RegistrationCompleted');
        break;
      default:
        break;
    }
  }, [step, activationCode, dispatch]);

  useEffect(() => {
    if (getRegisterCodeStatus === REQUEST_STATUS.ERROR) {
      /*
       * If code is invalid, navigate to login, if 404 page would exist, then we could navigate there
       * */
      routerHistory.push('/');
    }
  }, [getRegisterCodeStatus, routerHistory]);
  useEffect(() => {
    if (step === 4 && isJoinDocument) {
      redirectHelper.redirect();
    }
  }, [isJoinDocument, step]);

  const renderActivationStep = (() => {
    switch (step) {
      case 0:
        return <Loader />;
      case 1:
        return <PhoneVerificationForm activationCode={activationCode} />;
      case 2:
        return <PhoneConfirmForm activationCode={activationCode} />;
      case 3:
        return <PasswordForm type="createPasswordScreen" activationCode={activationCode} />;
      default:
        return 'UNDEFINED STEP';
    }
  })();

  const onMoveToAppClick = (toApp) => {
    if (tokenStatus === REQUEST_STATUS.SUCCESS || toApp) {
      redirectHelper.setRedirect(`${process.env.REACT_APP_APP_URL}/drive?s=joinWorkspace`);
      redirectHelper.redirect();
    } else {
      routerHistory.push('/');
    }
  };

  return step < 4 ? (
    <FullPageModal isOpen showLogo={step !== 0}>
      {renderActivationStep}
    </FullPageModal>
  ) : (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header noRedirect />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            {step === 4 && isJoinDocument ? (
              <Loader />
            ) : (
              <ConfirmationScreen
                imgSrc="/images/account-ready.svg"
                header={t('activationSuccessScreen.header')}
                text={t('activationSuccessScreen.info')}
                buttonLabel={t('activationSuccessScreen.buttonLabel')}
                buttonOnClick={onMoveToAppClick}
              />
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RegisterActivationPage;
