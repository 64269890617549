const links = (language) => {
  return {
    privacyPolicy:
      language === 'pl'
        ? 'https://www.doxychain.com/legal#privacy-pol'
        : 'https://www.doxychain.com/legal#privacy',
    termsOfService:
      language === 'pl'
        ? 'https://www.doxychain.com/legal#terms-pol'
        : 'https://www.doxychain.com/legal#terms',
    helpDesk: 'https://docs.doxychain.com',
  };
};

export default links;
