import React, { useState } from 'react';
import { Button, InputGroup, Form } from 'react-bootstrap';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PasswordMeter from './subcomponents/PasswordMeter/PasswordMeter';

const PasswordInput = (props) => {
  const [passwordInputType, setPasswordInputType] = useState('password');

  const {
    placeholder,
    value,
    onChange,
    isInvalid,
    feedback,
    withMeter,
    name,
    onChangeEvent,
  } = props;

  const togglePasswordVisibility = () => {
    if (passwordInputType === 'password') {
      setPasswordInputType('text');
    } else {
      setPasswordInputType('password');
    }
  };

  return (
    <>
      <InputGroup className="mb-3">
        <Form.Control
          className="password-input"
          name={name}
          isInvalid={isInvalid}
          placeholder={placeholder}
          type={passwordInputType}
          spellCheck={false}
          autoComplete="off"
          value={value}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.value);
            }
            if (onChangeEvent) {
              onChangeEvent(event);
            }
          }}
        />
        <Form.Control.Feedback className="font-weight-medium text text-body-small" type="invalid">
          {feedback}
        </Form.Control.Feedback>

        <div className={clsx('input-suffix', isInvalid && 'is-invalid')}>
          <Button
            className={clsx(passwordInputType === 'text' && 'text-color-primary')}
            variant="text"
            onClick={togglePasswordVisibility}
            tabIndex={-1}
          >
            <i className="fico fico-doxy-visibility" />
          </Button>
        </div>
      </InputGroup>
      {withMeter && (
        <div className="mb-3">
          <PasswordMeter value={value} />
        </div>
      )}
    </>
  );
};

PasswordInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onChangeEvent: PropTypes.func,
  isInvalid: PropTypes.bool,
  feedback: PropTypes.string,
  withMeter: PropTypes.bool,
};

export default PasswordInput;
