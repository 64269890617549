import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Container, Row } from 'react-bootstrap';
import CodeInput from './CodeInput';
import HelpInfo from '../../HelpInfo/HelpInfo';
import TimerButton from '../../TimerButton/TimerButton';

const TwoFactorForm = ({
  method,
  lastSendTimestamp,
  isSending,
  isReSending,
  hasErrors,
  onSend,
  onReSend,
  onClearErrors,
  twoFactorToken,
}) => {
  const [authCode, setAuthCode] = useState('');
  const { t } = useTranslation();

  const codeInputsRef = useRef(null);

  /*
   * Cooldown for resend
   * */
  const retryTimeout = 30000;

  // TODO: standarize with app front
  const translations = {
    header: t(`twoFactor.${method}.header`),
    desc: t(`twoFactor.${method}.desc`),
    label: t(`twoFactor.${method}.label`),
    hasNotArrived: t(`twoFactor.${method}.hasNotArrived`),
    retryTimer: t(`twoFactor.${method}.retryTimerText`),
    retryButton: t(`twoFactor.${method}.retryButton`),
    sendButton: t(`twoFactor.${method}.sendButton`),
    havingTrouble: t(`twoFactor.${method}.havingTrouble`),
    contactSupport: t(`twoFactor.${method}.contactSupport`),
    errorMessage: t(`twoFactor.${method}.errorMessage`),
  };

  /*
   * config for methods, that can resend codes
   * */
  const methodsWithTimer = ['sms'];

  useEffect(() => {
    if (twoFactorToken?.expiresAt) {
      setTimeout(() => {
        window.location.href = process.env.REACT_APP_AUTH_FRONT_URL;
      }, twoFactorToken.expiresAt - new Date().getTime());
    }
  }, [twoFactorToken]);

  const methodHasTimer = methodsWithTimer.indexOf(method) !== -1;

  useEffect(() => {
    if (authCode.length === 6 && !isSending) {
      onSend(authCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode]);

  return (
    <Container>
      <Row>
        <Col>
          <h2 className="mb-0 text-center text-md-left w-100">{translations.header}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="mb-7 text-center text-md-left w-100">{translations.desc}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4 htmlFor="authCode" className="text-center text-md-left w-100">
            {translations.label}
          </h4>
        </Col>
      </Row>

      {hasErrors && (
        <Row className="mb-6">
          <Col>
            <Alert
              className="d-flex w-100 justify-content-between align-items-center"
              variant="danger"
            >
              {translations.errorMessage}
              <Button
                className="m-0 p-0"
                variant="text"
                onClick={() => {
                  onClearErrors();
                }}
              >
                <i className="fico-doxy-close" />
              </Button>
            </Alert>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <CodeInput
            ref={codeInputsRef}
            codeLength={6}
            onValueChange={(newValue) => {
              setAuthCode(newValue);
            }}
            onAcceptValue={(code) => {
              onSend(code);
            }}
            hasErrors={hasErrors}
          />
        </Col>
      </Row>
      {methodHasTimer && (
        <Row className="two-factor-retry-timer text mt-2">
          <Col className="d-flex flex-column flex-sm-row  text-body-small align-items-baseline">
            {`${translations.hasNotArrived} `}
            <TimerButton
              cooldown={retryTimeout}
              buttonLabel={translations.retryButton}
              awaitMessage={` ${translations.retryTimer}`}
              buttonDisabled={isReSending}
              onClick={() => {
                onReSend();
                codeInputsRef.current.clearCode();
              }}
              initTimestamp={lastSendTimestamp}
            />
          </Col>
        </Row>
      )}
      <Row className="mt-7 mb-7">
        <Col>
          <button
            className="btn btn-primary w-100"
            type="button"
            onClick={() => onSend(authCode)}
            disabled={isSending}
          >
            {translations.sendButton}
          </button>
        </Col>
      </Row>
      <Row>
        <Col>
          <HelpInfo label={t('support.info')} linkLabel={t('support.link')} />
        </Col>
      </Row>
    </Container>
  );
};

TwoFactorForm.propTypes = {
  method: PropTypes.string.isRequired,
  lastSendTimestamp: PropTypes.number,
  isSending: PropTypes.bool,
  isReSending: PropTypes.bool,
  hasErrors: PropTypes.bool,
  onSend: PropTypes.func,
  onReSend: PropTypes.func,
  onClearErrors: PropTypes.func,
};

export default TwoFactorForm;
