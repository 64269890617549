import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import clsx from 'clsx';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import Header from '../../components/Header/Header';
import LoginOrRegisterButtons from '../../components/LoginOrRegisterButtons/LoginOrRegisterButtons';
import Footer from '../../components/Footer/Footer';
import authActions from '../../redux/actions/auth.actions';
import documentActions from '../../redux/actions/document.actions';
import filesModuleActions from '../../redux/actions/filesModule.actions';
import UserAvatarAndTextItem from '../../components/UserAvatarAndTextItem/UserAvatarAndTextItem';
import redirectHelper from '../../helpers/redirectHelper';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import Loader from '../../components/Loader/Loader';
import { documentDSLModule, documentModule } from '../../redux/services/document.service';
import { REQUEST_STATUS } from '../../models/common.model';
import identityActions from '../../redux/actions/identity.actions';
import alertsActions from '../../redux/actions/alerts.actions';

const DocumentInvitePage = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isCertificate =
    history.location.pathname.includes('certificate') ||
    history.location.pathname.includes('certyfikat');
  const redirectPath = isCertificate ? 'join-to-certificate' : 'join-to-document';
  const apiService = isCertificate ? documentDSLModule : documentModule;
  const { tokenStatus } = useSelector((state) => state.auth, undefined);
  const {
    registerIntoWorkspaceStatus,
    registerIntoWorkspaceErrors,
    registerConfirmationToken,
  } = useSelector((state) => state.identity, undefined);
  const { documentInvitationDetails, getDocumentInvitationStatus } = useSelector(
    (state) => state.document,
    undefined
  );
  const [isPreviewFull, setIsPreviewFull] = useState(false);
  const { getFileStatus, fileUrl } = useSelector((state) => state.filesModule, undefined);
  const { search } = useLocation();
  const pinCode = new URLSearchParams(search).get('pin');
  const pinCodeUrl = pinCode ? `?pin=${pinCode}` : '';
  const { inviteId } = match.params;
  const hasNoAccount =
    !documentInvitationDetails?.userId && documentInvitationDetails?.invitedEmail;
  const prevRegisterIntoWorkspaceStatus = useRef(null);

  const onLogin = () => {
    //  Set login, set next redirect after login
    const urlSuffix = documentInvitationDetails?.invitedEmail
      ? `?email=${documentInvitationDetails?.invitedEmail}&joinDocument=true`
      : '';

    redirectHelper.setRedirect(
      `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
    );
    history.push(`/${i18n.language}${urlSuffix}`);
  };

  const onRegister = () => {
    redirectHelper.setRedirect(
      `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
    );
    history.push(
      `/${i18n.language}/${t('urls.register')}?email=${
        documentInvitationDetails?.invitedEmail
      }&joinDocument=true`
    );
  };

  const onRegisterInvitation = () => {
    redirectHelper.setRedirect(
      `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
    );
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          dispatch(
            identityActions.registerToJoinDocument(
              inviteId,
              apiService,
              isCertificate ? undefined : i18n.language,
              token
            )
          );
        });
    });
  };

  const togglePreview = () => setIsPreviewFull(!isPreviewFull);

  useEffect(() => {
    if (!inviteId) {
      history.push(`/${i18n.language}`);
    } else {
      dispatch(documentActions.getDocumentInvitationDetails(inviteId, apiService));
      dispatch(authActions.checkTokenStatus(['AUTH_USER_GET_PROFILE']));
    }
  }, [history, inviteId, dispatch, apiService]);

  useEffect(() => {
    if (getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(filesModuleActions.getFile(documentInvitationDetails.imageDownloadToken));
    }
  }, [getDocumentInvitationStatus, documentInvitationDetails, dispatch]);

  useEffect(() => {
    if (
      (tokenStatus === REQUEST_STATUS.SUCCESS &&
        getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS) ||
      (getDocumentInvitationStatus === REQUEST_STATUS.SUCCESS &&
        tokenStatus === REQUEST_STATUS.SUCCESS)
    ) {
      window.location.href = `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`;
    } else if (hasNoAccount) {
      redirectHelper.setRedirect(
        `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
      );
      //  If user has no account, start creating account
      onRegisterInvitation();
    } else if (documentInvitationDetails?.invitedEmail) {
      //  Set login, set next redirect after login
      redirectHelper.setRedirect(
        `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
      );
      history.push(
        `/${i18n.language}?email=${documentInvitationDetails?.invitedEmail}&joinDocument=true`
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinCodeUrl, tokenStatus, getDocumentInvitationStatus, inviteId, redirectPath]);
  //
  // useEffect(() => {
  //   // dispatch(registerActions.getRegisterCodeStatus(activationCode));
  // }, [])

  useEffect(() => {
    if (prevRegisterIntoWorkspaceStatus.current === REQUEST_STATUS.PENDING) {
      if (registerIntoWorkspaceStatus === REQUEST_STATUS.ERROR) {
        const firstCode = registerIntoWorkspaceErrors[0]?.code || null;
        switch (firstCode) {
          case 'NOT_FOUND':
            dispatch(alertsActions.error(t('inviteWorkspacePage.inviteNotFound')));
            history.push(`/${i18n.language}`);
            break;
          case 'INTERNAL_ERROR':
          default:
            dispatch(alertsActions.error(t('inviteWorkspacePage.internalError')));
            break;
        }
      } else if (registerIntoWorkspaceStatus === REQUEST_STATUS.SUCCESS) {
        redirectHelper.setRedirect(
          `${process.env.REACT_APP_APP_URL}/${redirectPath}/${inviteId}${pinCodeUrl}`
        );
        history.push(
          `/${i18n.language}/${t('urls.activate')}/${registerConfirmationToken}&joinDocument=true`
        );
      }
    }
    prevRegisterIntoWorkspaceStatus.current = registerIntoWorkspaceStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registerIntoWorkspaceStatus]);

  const imageContent = (inModal) => (
    <div
      aria-hidden="true"
      onClick={inModal ? () => undefined : togglePreview}
      className={clsx(
        'invite-document-image-wrapper',
        isCertificate && !inModal && 'clickable',
        inModal && 'invite-document-image-wrapper--modal'
      )}
    >
      {fileUrl && getFileStatus === REQUEST_STATUS.SUCCESS ? (
        <img src={fileUrl} alt={documentInvitationDetails.documentName} />
      ) : (
        <Loader />
      )}
    </div>
  );

  return (
    <>
      <Container className="d-flex flex-column h-100" id="document-invite-page">
        <Row>
          <Col>
            <Header redirectType="register" noRedirect />
          </Col>
        </Row>
        <Row className="flex-fill align-items-center">
          {getDocumentInvitationStatus === REQUEST_STATUS.IDLE ||
          getDocumentInvitationStatus === REQUEST_STATUS.PENDING ? (
            <Loader />
          ) : (
            <>
              {getDocumentInvitationStatus === REQUEST_STATUS.ERROR ? (
                <Col>
                  <ConfirmationScreen
                    imgSrc="/images/error.svg"
                    header={t('documentInvitePage.fetchInvitationError')}
                  />
                </Col>
              ) : (
                <Col>
                  <div className="content-wrapper">
                    <Row
                      className="p-3 flex-fill align-items-center justify-content-center"
                      noGutters
                    >
                      <Col>
                        <Row className="d-flex">
                          <Col xs={12} md className="documentName">
                            <strong className="text-body-small text-color-primary label">
                              {t('documentInvitePage.documentName')}
                            </strong>
                            <h2>{documentInvitationDetails.documentName}</h2>
                          </Col>
                          <Col xs={12} md="auto">
                            <strong className="text-body-small text-color-primary label">
                              {t('documentInvitePage.author')}
                            </strong>
                            <UserAvatarAndTextItem
                              classes=""
                              tooltip={documentInvitationDetails.inviterName}
                              size="medium"
                              alt={documentInvitationDetails.inviterName}
                              badgeChildren={documentInvitationDetails.workspaceName}
                              badgeSize="mini"
                              text={documentInvitationDetails.inviterName}
                              additionalText={documentInvitationDetails.inviterName}
                              description={
                                documentInvitationDetails.inviterName ===
                                documentInvitationDetails.workspaceName
                                  ? ''
                                  : documentInvitationDetails.workspaceName
                              }
                            />
                          </Col>
                        </Row>
                        {imageContent(false)}
                        <div className="d-flex justify-content-center">
                          {getDocumentInvitationStatus === REQUEST_STATUS.IDLE ||
                          getDocumentInvitationStatus === REQUEST_STATUS.PENDING ? (
                            <Loader />
                          ) : (
                            <>
                              {hasNoAccount && (
                                <div className="login-register-buttons-wrapper text-center w-100">
                                  <h6 className="m-0 font-weight-semi-bold">
                                    {isCertificate
                                      ? t('documentInvitePage.registerToJoinCertificate')
                                      : t('documentInvitePage.registerToJoinDocument')}
                                  </h6>
                                  <Button
                                    className="w-100 text-color-primary mt-4 mt-sm-5 mb-2 mb-sm-5"
                                    variant="light"
                                    onClick={onRegisterInvitation}
                                  >
                                    {t('loginRegisterButtons.newAccount')}
                                  </Button>
                                </div>
                              )}

                              {!hasNoAccount && documentInvitationDetails?.invitedEmail && (
                                <div className="login-register-buttons-wrapper text-center w-100">
                                  <h6 className="m-0 font-weight-semi-bold">
                                    {t('loginRegisterButtons.heading')}
                                  </h6>
                                  <Button
                                    className="w-100 mt-4 mt-sm-5 mb-2 mb-sm-5"
                                    onClick={onLogin}
                                  >
                                    {t('loginRegisterButtons.login')}
                                  </Button>
                                </div>
                              )}
                              {!hasNoAccount && !documentInvitationDetails?.invitedEmail && (
                                <LoginOrRegisterButtons
                                  onLogin={() => {
                                    onLogin();
                                  }}
                                  onRegister={() => {
                                    onRegister();
                                  }}
                                  isLoggedIn={tokenStatus === REQUEST_STATUS.SUCCESS}
                                  loggedLabel={t('documentInvitePage.signDocument')}
                                  isDisabled={false}
                                  withTerms={false}
                                />
                              )}
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col>
            <Footer />
          </Col>
        </Row>
      </Container>
      <Modal show={isPreviewFull} onHide={togglePreview} centered size="xl">
        {imageContent(true)}
      </Modal>
    </>
  );
};

export default DocumentInvitePage;
