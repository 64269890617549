import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import RegisterForm from '../../components/pages/Register/RegisterForm';
import i18n from '../../helpers/i18n';
import registerActions from '../../redux/actions/register.actions';
import ConfirmationScreen from '../../components/ConfirmationScreen/ConfirmationScreen';
import identityActions from '../../redux/actions/identity.actions';
import { REQUEST_STATUS } from '../../models/common.model';
import { userComActions, UserComRegistrationSteps } from '../../redux/actions/userCom.actions';

const RegisterPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const registerStatus = useSelector((state) => state.register.registerStatus);
  const registerIntoWorkspaceStatus = useSelector(
    (state) => state.identity.registerIntoWorkspaceStatus
  );
  const registerConfirmationToken = useSelector(
    (state) => state.identity.registerConfirmationToken
  );
  const reSendEmailStatus = useSelector((state) => state.register.reSendEmailStatus);
  const [registeredEmail, setRegisteredEmail] = useState('');
  const { email: emailPassed, inviteId } = useParams();
  const history = useHistory();
  const { language } = i18n;

  const handleFormSubmit = (email) => {
    setSubmitDisabled(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA, { action: 'submit' })
        .then((token) => {
          userComActions.userRegistration(UserComRegistrationSteps.USER_SUBMITTED_EMAIL);
          if (emailPassed) {
            dispatch(identityActions.registerIntoWorkspace(inviteId, language, token));
          } else {
            dispatch(registerActions.register(email, language, token));
          }
        });
    });
    setRegisteredEmail(email);
  };

  useEffect(() => {
    if (registerIntoWorkspaceStatus === REQUEST_STATUS.SUCCESS) {
      history.push(`/${language}/${t('urls.activate')}/${registerConfirmationToken}`);
    }
  }, [registerConfirmationToken, registerIntoWorkspaceStatus, history, language, t]);

  useEffect(() => {
    document.title = t('titles.register');
    return () => {
      dispatch(registerActions.resetRegisterStatus());
    };
  }, [dispatch, t]);

  useEffect(() => {
    if (registerStatus === REQUEST_STATUS.ERROR) {
      setSubmitDisabled(false);
    }
  }, [registerStatus]);

  useEffect(() => {
    userComActions.userRegistration(UserComRegistrationSteps.USER_OPENED_REGISTRATION_PAGE);
  }, []);

  /*
  * TODO: If needed change it to Alert component inside ConfirmationScreen
  * useEffect(() => {
    if (reSendEmailStatus === REQUEST_STATUS.ERROR) {
      dispatch(alertsActions.error(t('registerConfirmationScreen.errorResend')));
    }
  }, [dispatch, reSendEmailStatus, t]); */

  const handleResendEmail = () => {
    dispatch(registerActions.reSendActivationEmail(registeredEmail));
  };

  return (
    <>
      <Container className="d-flex flex-column h-100">
        <Row className="pl-3 pr-3">
          <Col>
            <Header redirectType="login" />
          </Col>
        </Row>
        <Row className="p-3 flex-fill align-items-center">
          <Col>
            {registerStatus === REQUEST_STATUS.SUCCESS ? (
              <ConfirmationScreen
                imgSrc="/images/check-mail.svg"
                header={t('registerConfirmationScreen.header')}
                text={t('registerConfirmationScreen.info')}
                email={registeredEmail}
                resendEmail={handleResendEmail}
                reSendPending={reSendEmailStatus === REQUEST_STATUS.PENDING}
              />
            ) : (
              <RegisterForm
                submitForm={(email) => handleFormSubmit(email)}
                disabled={submitDisabled}
                passedEmail={emailPassed}
              />
            )}
          </Col>
        </Row>
        {registerStatus !== REQUEST_STATUS.SUCCESS && (
          <Row className="pl-3 pr-3">
            <Col>
              <Footer />
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default RegisterPage;
