import React from 'react';
import { Button, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import UserAvatar from '../../components/UserAvatar/UserAvatar';
import authActions from '../../redux/actions/auth.actions';
import redirectHelper from '../../helpers/redirectHelper';

const DifferentMailInfo = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { invitationDetails } = useSelector((state) => state.identity);
  const dispatch = useDispatch();

  const onLogout = () => {
    redirectHelper.setRedirect(history.location.pathname);

    dispatch(
      authActions.deleteToken(() =>
        history.push(
          `/${i18n.language}?email=${invitationDetails?.invitedEmail}&joinWorkspace=true`
        )
      )
    );
  };

  return (
    <Col xs={12} md={{ span: 6, offset: 3 }}>
      <div className="d-flex justify-content-center">
        <UserAvatar size="extraLarge">{invitationDetails?.name}</UserAvatar>
      </div>
      <h2 className="text-center text-color-primary mb-2">{invitationDetails?.name}</h2>
      <h1 className="text-center mb-2 mb-sm-6">{t('differentMailInfo.title')}</h1>
      <h6 className="text-center mb-1">{t('differentMailInfo.invitedMail')}</h6>
      <h6 className="text-center mb-2 mb-sm-6 link font-weight-default">
        {invitationDetails?.invitedEmail}
      </h6>
      <Button
        className="d-flex align-items-center justify-content-center mt-5 w-100 btn-medium shadow-none"
        variant="primary"
        type="button"
        onClick={onLogout}
      >
        {t('differentMailInfo.button')}
      </Button>
    </Col>
  );
};

export default DifferentMailInfo;
