import registerConstants from '../constants/register.constants';
import { REQUEST_STATUS } from '../../models/common.model';

const initialState = {
  registerStatus: REQUEST_STATUS.IDLE,
  reSendEmailStatus: REQUEST_STATUS.IDLE,
  getRegisterCodeStatus: REQUEST_STATUS.IDLE,
  confirmPhoneStatus: REQUEST_STATUS.IDLE,
  reSendActivationSmsStatus: REQUEST_STATUS.IDLE,
  setPasswordStatus: REQUEST_STATUS.IDLE,
  setPhoneNumberStatus: REQUEST_STATUS.IDLE,
  registerCodeStatus: null,
  registerSmsLastTimestamp: 0,
  setPhoneNumberError: false,
  activationStep: 0,
};

const getActivationStepByStatusCode = (status) => {
  switch (status?.stage) {
    case 'PASSWORD_SETUP_STAGE':
      return 3;
    case 'PHONE_CONFIRMATION_STAGE':
      return 2;
    case 'PHONE_VALIDATION_STAGE':
    default:
      return 1;
  }
};

function register(state = initialState, action) {
  switch (action.type) {
    case registerConstants.REGISTER_REQUEST:
      return {
        ...state,
        registerStatus: REQUEST_STATUS.PENDING,
      };
    case registerConstants.REGISTER_SUCCESS:
      return {
        ...state,
        registerStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.REGISTER_FAILURE:
      return {
        ...state,
        registerStatus: REQUEST_STATUS.ERROR,
      };
    case registerConstants.RESET_REGISTER_STATUS:
      return {
        ...state,
        registerStatus: REQUEST_STATUS.IDLE,
      };
    case registerConstants.ADD_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        setPhoneNumberStatus: REQUEST_STATUS.PENDING,
        setPhoneNumberError: false,
      };
    case registerConstants.ADD_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        activationStep: state.activationStep + 1,
        registerSmsLastTimestamp: Date.now(),
        setPhoneNumberStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.ADD_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        setPhoneNumberStatus: REQUEST_STATUS.ERROR,
        setPhoneNumberError: action.error,
      };
    case registerConstants.SET_PASSWORD_REQUEST:
      return {
        ...state,
        setPasswordStatus: REQUEST_STATUS.PENDING,
      };
    case registerConstants.SET_PASSWORD_SUCCESS:
      return {
        ...state,
        activationStep: state.activationStep + 1,
        setPasswordStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.SET_PASSWORD_FAILURE:
      return {
        ...state,
        setPasswordStatus: REQUEST_STATUS.ERROR,
      };
    case registerConstants.RE_SEND_ACTIVATION_EMAIL_REQUEST:
      return {
        ...state,
        reSendEmailStatus: REQUEST_STATUS.PENDING,
      };
    case registerConstants.RE_SEND_ACTIVATION_EMAIL_SUCCESS:
      return {
        ...state,
        reSendEmailStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.RE_SEND_ACTIVATION_EMAIL_FAILURE:
      return {
        ...state,
        reSendEmailStatus: REQUEST_STATUS.ERROR,
      };
    case registerConstants.GET_ACTIVATION_CODE_STATUS_REQUEST:
      return {
        ...state,
        getRegisterCodeStatus: REQUEST_STATUS.PENDING,
      };
    case registerConstants.GET_ACTIVATION_CODE_STATUS_SUCCESS:
      return {
        ...state,
        registerCodeStatus: action.data.stage,
        activationStep: getActivationStepByStatusCode(action.data),
        getRegisterCodeStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.GET_ACTIVATION_CODE_STATUS_FAILURE:
      return {
        ...state,
        getRegisterCodeStatus: REQUEST_STATUS.ERROR,
      };
    case registerConstants.VERIFY_PHONE_NUMBER_REQUEST:
      return {
        ...state,
        confirmPhoneStatus: REQUEST_STATUS.PENDING,
      };
    case registerConstants.VERIFY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        confirmPhoneStatus: REQUEST_STATUS.SUCCESS,
        activationStep: state.activationStep + 1,
      };
    case registerConstants.VERIFY_PHONE_NUMBER_FAILURE:
      return {
        ...state,
        confirmPhoneStatus: REQUEST_STATUS.ERROR,
      };
    case registerConstants.RE_SEND_CONFIRMATION_CODE_REQUEST:
      return {
        ...state,
        reSendActivationSmsStatus: REQUEST_STATUS.PENDING,
        registerSmsLastTimestamp: Date.now(),
      };
    case registerConstants.RE_SEND_CONFIRMATION_CODE_SUCCESS:
      return {
        ...state,
        reSendActivationSmsStatus: REQUEST_STATUS.SUCCESS,
      };
    case registerConstants.RE_SEND_CONFIRMATION_CODE_FAILURE:
      return {
        ...state,
        reSendActivationSmsStatus: REQUEST_STATUS.ERROR,
      };
    default:
      return state;
  }
}

export default register;
