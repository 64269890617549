export const UserComEvents = {
  USER_REGISTRATION: 'event.user_registration',
};

export const UserComRegistrationSteps = {
  USER_OPENED_REGISTRATION_PAGE: '1a_user_opened_registration_page',
  USER_SUBMITTED_EMAIL: '1b_user_submitted_email',
  USER_OPENED_PHONE_NUMBER_PAGE: '2a_user_opened_phone_number_page',
  USER_SUBMITTED_PHONE_NUMBER: '2b_user_submitted_phone_number',
  USER_OPENED_2FA: '3a_user_opened_2fa_code_page',
  USER_SUBMITTED_2FA: '3b_user_submitted_2fa_code',
  USER_OPENED_PASSWORD_PAGE: '4a_user_opened_password_page',
  USER_SUBMITTED_PASSWORD: '4b_user_submitted_password',
};

const userRegistration = (step) => {
  if (window?.userengage)
    window.userengage(UserComEvents.USER_REGISTRATION, {
      step,
    });
};
export const userComActions = { userRegistration };
